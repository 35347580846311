import React, {useEffect, useState} from "react"
import Layout from "../components/Layout";
import { Link } from "react-router-dom"
import CardItem from "../components/CardItem";
import { useNavigate } from 'react-router';
import Spinner from "../components/Spinner";
import useClock from "../hooks/useClock";
import CogLoader from "../components/CogLoader"
//import {useBlocker } from 'react-router-dom'

const Breed = ({
}) => {
    let navigate = useNavigate();

    const [father, setFather] = useState(undefined);
    const [mother, setMother] = useState(undefined);
    const [jr, setJr] = useState(undefined);

    const [jrCooldown, setJrCooldown] = useState(undefined)
    const [fatherData, setFatherData] = useState(undefined)
    const [motherData, setMotherData] = useState(undefined)

    const [now, setNow] = useState( Math.floor(Date.now() / 1000))

    useClock(() => {
        setNow(_ => Math.floor(Date.now() / 1000));
    })

    const canReveal = () => father !== -1 && now >= jrCooldown;

    //useBlocker(() => {
    //    return "Please wait until your Junior is revealed."
    //}, (father !== -1 ? !canReveal() : false))

    useEffect(() => {
        let _father = localStorage.getItem("father")
        let _mother = localStorage.getItem("mother")
        let _jr = localStorage.getItem("jr")

        let _jrCooldown = localStorage.getItem("jr_cooldown")
        let _fatherData = localStorage.getItem("fatherData")
        let _motherData = localStorage.getItem("motherData")

        console.log("Following items found in local storage")
        console.log(_father)
        console.log(_mother)
        console.log(_jr)
        console.log(_jrCooldown)
        console.log(_fatherData)
        console.log(_motherData)

        if (_father && _mother && _jr && _fatherData && _motherData) {
            setFather(parseInt(_father))
            setMother(parseInt(_mother))
            setJr(parseInt(_jr))
            setFatherData(JSON.parse(_fatherData));
            setMotherData(JSON.parse(_motherData))
            setJrCooldown(parseInt(_jrCooldown))
        } else {
            setFather(-1)
        }
    }, [])

    const reveal = () => {        
        if (canReveal()) {            
            localStorage.removeItem("father")
            localStorage.removeItem("mother")
            localStorage.removeItem("fatherData")
            localStorage.removeItem("motherData")            
            navigate("/junior")           
        }
    }

    return (<Layout>
        <main className="section main-section">
        {
            father && father !== -1 &&
            <div className="container">
                <div className="wrapper card my-sloties-card">
                <h2 className="heading heading-large centred">Congratulations!</h2>
                <div className="horizontal-line" />
                <div className="spacer _3em" />
                <div className="grid slotie-parents-grid">
                    {
                        fatherData &&
                        <CardItem
                            title={fatherData.name}
                            image={fatherData.image}
                            breedStatus={3}
                            key={fatherData.id}
                            id={fatherData.id}
                            isJunior={fatherData.isJunior}
                            isSelectable={false}
                        />
                    }

                    {
                        motherData &&
                        <CardItem
                            title={motherData.name}
                            image={motherData.image}
                            breedStatus={3}
                            key={motherData.id}
                            id={motherData.id}
                            isJunior={motherData.isJunior}
                            isSelectable={false}
                        />
                    }

                </div>
                <div className="spacer _3em" />
                {
                    canReveal() &&
                    <div className="grid junior-reveal-grid">
                        <span id="w-node-a29ffaf2-3ce8-e413-29bc-85482044bfa6-ca510333" className="link-block slotie-junior-loading-link w-inline-block">
                        <div className="grid single-slotie-grid selectable-slotie loading-junior">
                            <div id="w-node-a4acbdd6-c505-9fed-00e8-de1a3e8bd44a-ca510333" className="wrapper junior-reveal-wrapper">
                            <div data-w-id="35b0155a-9391-907b-f6f8-8adef6aeb330" data-animation-type="lottie" data-src="https://uploads-ssl.webflow.com/61a2301c1aba757f9b2521aa/61a2301c1aba75799b2521d9_lottieflow-loading-04-1-ffffff-easey.json" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="3.1458333333333335" data-duration={0} className="lottie loading-lottie" />
                            <div className="spacer _1-5em" />
                            <div className="heading heading-medium" onClick={(e) => {                                
                                //e.preventDefault()                                
                                reveal()
                            }}>Tap to reveal your Junior!</div>
                            </div>
                        </div>
                        </span>
                    </div>
                }
                {
                    !canReveal() &&
                    <div className="grid junior-reveal-grid">
                        <span id="w-node-a29ffaf2-3ce8-e413-29bc-85482044bfa6-ca510333" className="link-block slotie-junior-loading-link w-inline-block">
                        <div className="grid single-slotie-grid selectable-slotie loading-junior">
                            <div id="w-node-a4acbdd6-c505-9fed-00e8-de1a3e8bd44a-ca510333" className="wrapper junior-reveal-wrapper">
                            <CogLoader />
                            <div className="spacer _1-5em" />
                            <div className="heading heading-medium">Your junior will be ready in {jrCooldown - now} seconds.</div>
                            </div>
                        </div>
                        </span>
                    </div>
                }
                </div>
            </div>
        }
        {
            father == -1 &&
            <div className="container">
                <div className="wrapper card my-sloties-card">
                <h2 className="heading heading-large centred">Breed you Sloties to see your Junior</h2>
                <div className="horizontal-line" />
                <div className="spacer _3em" />
                <div className="grid breed-state-grid">
                    <Link to="/select" className="button w-button">Breed my Sloties.</Link>
                </div>
                </div>
            </div>
        }
            <div className="grid breed-state-grid" style={{ justifyContent: 'center', display: !father ? 'grid' : 'none' }}>
                <Spinner text={"Loading..."} />
            </div>
        </main>
    </Layout>)
}

export default Breed
