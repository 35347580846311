import React, {useState, useEffect} from "react"
import Layout from "../components/Layout";
import { Link } from "react-router-dom"
import CardItem from "../components/CardItem";
import { useNavigate } from 'react-router';
import axios from "axios";
import Spinner from "../components/Spinner";
import useClock from "../hooks/useClock";
import CogLoader from "../components/CogLoader";
import proofs from "../proofs/proofs.json";
import ConnectButton from "../components/ConnectButton";
const throttledQueue = require('throttled-queue');
const throttle = throttledQueue(10, 1000, true);

const Status = ({
    address,
    userSigner,
    web3Modal,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    readContracts
}) => {
    let navigate = useNavigate();
    const [slotieId, setSlotieId] = useState(1);
    const [slotieNumber, setSlotieNumber] = useState('');
    const [mySloties, setMySloties] = useState(undefined);
    const [isFetching, setIsFetching] = useState(undefined);
    const [error, setError] = useState(undefined);
    const isConnected = () => userSigner !== undefined && address !== undefined;

    async function getSlotieCardData() {
        if (error)
            return;
        try {
            setIsFetching(true)
            let _token = parseInt(slotieId);            
            let _lastMinted = (await readContracts.Slotie.nextTokenId()).toNumber()
            console.log(_lastMinted)
            console.log(_token)
            if (_lastMinted <= _token) {
                setError(`Slotie ${_token} has not been minted yet.`)
                return;
            }

            let _tokenUri = await readContracts.Slotie.tokenURI(_token);
            let metadata = await axios.get(_tokenUri);
            metadata = metadata.data
            let image =  metadata.image ? metadata.image.replace("gateway.pinata.cloud", "sloties.mypinata.cloud") :  metadata.image
            let lastBred = (await  readContracts.SlotieJrBreeding.slotieToLastBreedTimeStamp(_token)).toNumber();
            let _proof = proofs && proofs.proofs ? proofs.proofs[_token.toString()] || [] : [];

            let breedStatus;
            let breedStart;
            let now = Math.floor(Date.now() / 1000)

            if (lastBred === 0) {
                breedStart = _proof[0];
                breedStatus = now >= breedStart ? 1 : 0
            } else {
                breedStart = lastBred + 86400 * 120;
                breedStatus = now >= breedStart ? 1 : 4
            }

            const bred = (await readContracts.SlotieJrBreeding.bornJuniors()).toNumber();
            const maxBreedable = (await readContracts.SlotieJrBreeding.maxBreedableJuniors()).toNumber()

            setMySloties({
                id: _token,
                name: metadata.name,
                image: image,
                breedStatus: bred >= maxBreedable ? 0 : breedStatus,
                breedStart,
                proof: _proof,
                isJunior: false
            })
            setIsFetching(false)
        } catch(e) {
            setError("An unexpected error occurred. Please try again or submit a support ticket.")
        }
        
    }

    useEffect(() => {
        if (slotieId && readContracts && readContracts.Slotie) {
            getSlotieCardData();
        }
    }, [slotieId, readContracts, readContracts.Slotie])

    return (
    <Layout
        loadWeb3Modal={loadWeb3Modal}
        address={address}
        userSigner={userSigner}
        web3Modal={web3Modal}
    >
    <main className="section main-section">
        <div className="container">
            <div className="wrapper card my-sloties-card">
            <h2 className="heading heading-large centred">Check the breeding status of any Slotie</h2>
            <div className="horizontal-line" />
            <div className="spacer _3em" />
            {
                isConnected() && mySloties &&
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                    <input onChange={value => setSlotieNumber(value.target.value)} style={{ height: 50, padding: '0px 40px', color: '#000', outline: 'none', borderRadius: 6, marginRight: 5, border: 0 }} type="number" placeholder="Check" />
                    <button onClick={() => {
                        setError(undefined)

                        let parsed = parseInt(slotieNumber);
                        if (isNaN(parsed)) {
                            setError("Please enter a number between 1 and 10.000");
                            return
                        }      
                        if (parsed < 1 || parsed > 10000) {
                            setError("Please enter a number between 1 and 10.000");
                            return
                        }      

                        setSlotieId(parsed)
                    }} className="button w-button">Check</button>
                </div>
            }

            <div className="grid new-junior-grid">
                {
                    isConnected() && mySloties && !isFetching && !error &&
                    <CardItem
                        key={mySloties.id}
                        id={mySloties.id}
                        title={mySloties.name}
                        image={mySloties.image}
                        breedStatus={mySloties.breedStatus}
                        isJunior
                        isSelectable={mySloties.breedStatus === 1}
                        remainingDate={new Date(mySloties.breedStart * 1000)}
                    />
                }
               
            </div>
            <div className="grid slotie-select-button-grid">
                {
                    isConnected() && isFetching && !error &&
                    <div className="grid breed-state-grid" style={{ justifyContent: 'center', display: (isConnected() && isFetching) ? 'grid' : 'none' }}>
                    <Spinner text={"Slotie is loading..."} />
                  </div>
                }
                {
                    error &&
                    <div className="grid breed-state-grid">
                        <div className="text text-small" style={{color: "red"}}>{error}</div>
                    </div>
                }
                {
                    !isConnected() &&
                    <div className="grid breed-state-grid" style={{ justifyContent: 'center' }}>
                        <ConnectButton
                        loadWeb3Modal={loadWeb3Modal}
                        address={address}
                        userSigner={userSigner}
                        web3Modal={web3Modal}
                    />
                    </div>
                }
            </div>
            <div className="spacer _3em" />
            </div>
        </div>
    </main>
  </Layout>)
}

export default Status
