import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Layout = (props) => {
  //const [connected, setConnected] = useState(false)
  const connected = () => props.userSigner !== undefined && props.address !== undefined

  useEffect(() => {
    if (props.web3Modal && props.loadWeb3Modal && !props.isHome && props.web3Modal.cachedProvider) {
      props.loadWeb3Modal();
    }
  }, [props.loadWeb3Modal]);

  return (
    <div className="page-wrapper">
      <div className="nav">
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar w-nav"
        >
          <div className="wrapper navbar-wrapper">
            <div className="container navbar-container">
              <div className="grid nav-wallet-connection-grid">
                <a href="https://www.slotie.com" className="brand w-nav-brand">
                  <img
                    src="images/Slotie-Logo.svg"
                    loading="eager" alt="Slotie Logo."
                    className="logo nav-logo"
                  />
                  <div className="text logo-text">Slotie</div>
                </a>
                {
                  connected() ?
                    (
                      <div className="tag">
                        Wallet Connected <span className="span" />
                      </div>
                    ) : <a onClick={props.loadWeb3Modal} className="tag connect-wallet-tag w-button">Connect Wallet</a>
                }
              </div>
              <nav role="navigation" className="nav-menu w-nav-menu">
                <div className="grid nav-menu-grid">
                  <div className="grid nav-links-grid">
                    {
                      !props.isHome ?
                        <Link to="/" className="nav-link w-nav-link">Home</Link> :
                        <a href="#My-Sloties" className="nav-link w-nav-link">View Sloties</a>
                    }
                  </div>
                  <div className="grid social-links-grid">
                    <a
                      id="w-node-_8f78bbae-74c8-aa00-d66c-8c5459c9010e-c6fed3b0"
                      href="https://twitter.com/SlotieNft"
                      target="_blank"
                      className="link-block social-link w-inline-block"
                    >
                      <img src="images/Logo-white-2.svg" loading="eager" alt="Twitter Logo." className="icon social-icon" />
                    </a>
                    <a
                      href="https://discord.gg/slotie"
                      target="_blank"
                      className="link-block social-link w-inline-block"
                    >
                      <img src="images/Discord-Logo-White-1.svg" loading="eager" alt="Discord Logo." className="icon social-icon" />
                    </a>
                    <a
                      href="https://rarible.com/slotie/items"
                      target="_blank"
                      className="link-block social-link w-inline-block"
                    >
                      <img src="images/Opensea-Logo.svg" loading="eager" alt="Opensea Logo." className="icon social-icon" />
                    </a>
                  </div>
                </div>
                <div className="horizontal-line navbar-line mobile-navbar-line" />
              </nav>
              <div className="menu-button w-nav-button">
                <div className="w-icon-nav-menu"/>
              </div>
            </div>
          </div>
        </div>
        <div className="horizontal-line navbar-line"/>
      </div>

      {props.children}

      <footer className="section footer-section">
        <div className="container footer-container">
          <div className="grid footer-grid">
            <a href="https://www.slotie.com" id="w-node-fa8bc22b-940a-a591-4ef0-fb26e11756cb-c6fed3b0"
               className="brand w-nav-brand"
            >
              <img src="images/Slotie-Logo.svg" loading="eager" alt="Slotie Logo." className="logo footer-logo" />
              <div className="text logo-text footer-logo-text">Slotie</div>
            </a>
            <div className="grid footer-subgrid">
              <div className="text">Become a member of the strongest and fast growing DeFi gambling community.<br /></div>
              <div className="grid social-links-grid">
                <a
                  id="w-node-_85111364-03ec-d5ca-8481-6c611792f731-c6fed3b0"
                  href="https://twitter.com/SlotieNft"
                  target="_blank"
                  className="link-block social-link w-inline-block"
                >
                  <img
                    src="images/Logo-white-2.svg"
                    loading="eager"
                    alt="Twitter Logo."
                    className="icon social-icon"
                  />
                </a>
                <a
                  href="https://discord.gg/slotie"
                  target="_blank"
                  className="link-block social-link w-inline-block"
                >
                  <img src="images/Discord-Logo-White-1.svg"
                       loading="eager"
                       alt="Discord Logo."
                       className="icon social-icon"
                  />
                </a>
                <a
                  href="https://rarible.com/slotie/items"
                  target="_blank"
                  className="link-block social-link w-inline-block"
                >
                  <img src="images/Opensea-Logo.svg" loading="eager"
                       alt="Opensea Logo."
                       className="icon social-icon"
                  />
                </a>
              </div>
            </div>
            <div className="horizontal-line grow" />
            <div className="grid footer-subgrid">
              <div className="text text-small">© 2022 Slotie NFT. All rights reserved.<br /></div>
              <a href="https://www.slotie.com/terms" target="_blank"
                 className="link terms-link">Terms &amp; Conditions</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
};

export default Layout
