import React, {useState, useEffect} from "react"
import Layout from "../components/Layout";
import { Link } from "react-router-dom"
import CardItem from "../components/CardItem";
import { useNavigate } from 'react-router';
import axios from "axios";
import Spinner from "../components/Spinner";
import useClock from "../hooks/useClock";
import CogLoader from "../components/CogLoader";
const throttledQueue = require('throttled-queue');
const throttle = throttledQueue(10, 1000, true);


const Junior = ({
    address,
    userSigner,
    web3Modal,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    readContracts
}) => {
    let navigate = useNavigate();
    const [jr, setJr] = useState(undefined);
    const [jrData, setJrData] = useState(undefined)
    const [jrCooldown, setJrCooldown] = useState(undefined)

    const [now, setNow] = useState(Math.floor(Date.now() / 1000))

    useClock(() => {
        setNow(_ => Math.floor(Date.now() / 1000));
    })

    const canReveal = () => jr !== -1 && now >= jrCooldown;

    useEffect(() => {
        let _jr = localStorage.getItem("jr")
        let _jrCooldown = localStorage.getItem("jr_cooldown")

        console.log("Following items found in local storage")
        console.log(_jr)

        if (_jr) {
            setJrCooldown(parseInt(_jrCooldown))
            setJr(parseInt(_jr - 1))
        } else {
            setJr(-1)
        }
    })

    useEffect(() => {
        (async function() {
            if (jr !== undefined && jr !== -1 && readContracts && readContracts.SlotieJr) {
                let _token = jr;
                let _tokenUri = await readContracts.SlotieJr.tokenURI(_token);
                let metadata = await axios.get(_tokenUri);
                metadata = metadata.data
                let image =  metadata.image //? metadata.image.replace("gateway.pinata.cloud", "sloties.mypinata.cloud") :  metadata.image
                console.log(metadata)
                setJrData({
                    id: _token,
                    name: metadata.name,
                    image: image,
                    breedStatus: null,
                    breedStart: null,
                    proof: null,
                    isJunior: true
                })
            }
        })()
    }, [jr, readContracts])

    const goToHome = () => {
        localStorage.removeItem("jr")
        localStorage.removeItem("jr_cooldown")
        navigate("/my-sloties")
    }

    return (
    <Layout
        loadWeb3Modal={loadWeb3Modal}
        address={address}
        userSigner={userSigner}
        web3Modal={web3Modal}
    >
    <main className="section main-section">
     {
         jr !== undefined && jr !== -1 && jrData &&
        <div className="container">
            <div className="wrapper card my-sloties-card">
            <h2 className="heading heading-large centred">Your new Slotie Junior is born!</h2>
            <div className="horizontal-line" />
            <div className="spacer _3em" />
            <div className="grid new-junior-grid">
                {
                    jrData && canReveal() &&
                    <CardItem
                        title={jrData.name}
                        image={jrData.image}
                        id={jrData.id}
                        breedStatus={0}
                        isJunior
                    />
                }
                {/*<div id="w-node-_1336232c-9c34-fc72-c6f2-f34ba2ced908-61896816" className="grid single-slotie-grid new-junior"><img src="../images/NFT-Aztec-God---Optimised---V2.jpg" loading="lazy" sizes="(max-width: 767px) 82vw, (max-width: 991px) 21vw, 25vw" srcSet="../images/NFT-Aztec-God---Optimised---V2-p-500.jpeg 500w, ../images/NFT-Aztec-God---Optimised---V2-p-800.jpeg 800w, ../images/NFT-Aztec-God---Optimised---V2-p-1080.jpeg 1080w, ../images/NFT-Aztec-God---Optimised---V2.jpg 1500w" alt="" className="image slotie-image" />*/}
                {/*  <div className="wrapper slotie-card-detail-wrapper">*/}
                {/*    <div className="grid slotie-title-grid">*/}
                {/*      <div className="heading heading-small no-margin">Slotie #0000</div>*/}
                {/*      <a href="https://rarible.com/slotie/items" target="_blank" className="link-block social-link w-inline-block"><img src="../images/Opensea-Logo.svg" loading="eager" alt="Opensea Logo." className="icon social-icon" /></a>*/}
                {/*    </div>*/}
                {/*    <div className="spacer _0-5em" />*/}
                {/*    <div className="horizontal-line" />*/}
                {/*    <div className="spacer _0-75em" />*/}
                {/*    <div className="grid slotie-meta-grid">*/}
                {/*      <div className="tag loading-tag">Loading Breeding</div>*/}
                {/*      <div className="divider">•</div>*/}
                {/*      <div className="wrapper timer-wrapper"><img src="../images/Clock-Icon.svg" loading="lazy" alt="" className="icon timer-icon" />*/}
                {/*        <div className="spacer _0-5em" />*/}
                {/*        <div className="text text-xs">Fast <span className="text cooldown-time-text">(1d)</span></div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
            </div>
            {
                    jrData && !canReveal() &&
                    <div className="grid junior-reveal-grid">
                        <Link id="w-node-a29ffaf2-3ce8-e413-29bc-85482044bfa6-ca510333" to="#" className="link-block slotie-junior-loading-link w-inline-block">
                        <div className="grid single-slotie-grid selectable-slotie loading-junior">
                            <div id="w-node-a4acbdd6-c505-9fed-00e8-de1a3e8bd44a-ca510333" className="wrapper junior-reveal-wrapper">
                            <CogLoader />
                            <div className="spacer _1-5em" />
                            <div className="heading heading-medium">Your junior will be ready in {jrCooldown - now} seconds.</div>
                            </div>
                        </div>
                        </Link>
                    </div>
                }
            <div className="spacer _3em" />
            {
                jrData && canReveal() &&
                <div className="wrapper button-row centred-button-row">
                    <Link to="#" className="button w-button" onClick={(e) => {
                        e.preventDefault()
                        goToHome();
                    }}>View all Sloties</Link>
                </div>
            }
            </div>
        </div>
      }
      {
            jr == -1 &&
            <div className="container">
                <div className="wrapper card my-sloties-card">
                <h2 className="heading heading-large centred">Breed you Sloties to see your Junior</h2>
                <div className="horizontal-line" />
                <div className="spacer _3em" />
                <div className="grid breed-state-grid">
                    <Link to="/select" className="button w-button">Breed my Sloties.</Link>
                </div>
                </div>
            </div>
        }
        {
            !jr || (jr !== -1 && !jrData) &&
            <div className="container">
                <div className="wrapper card my-sloties-card">
                <h2 className="heading heading-large centred">Breed you Sloties to see your Junior</h2>
                <div className="horizontal-line" />
                <div className="spacer _3em" />
                <div className="grid breed-state-grid">
                    <Spinner text={"Loading..."} />
                </div>
                </div>
            </div>
        }
    </main>
  </Layout>)
}

export default Junior
