import React, { useEffect, useState } from "react"
import CardItem from "../components/CardItem";
import moment from 'moment'
import { Link } from 'react-router-dom';
import useSlotieNFTs from "../hooks/useSlotieNFT";
import useSlotieJrNFTs from "../hooks/useSlotieJrNFT"
import Spinner from "./Spinner";
import ConnectButton from "./ConnectButton";

const BREEDING_START = '2022-02-11 00:00:01'

const MySlotiesItems = ({
    address,
    contractNFT,
    contractNFTJr,
    contractBreeding,
    contractTransfer,
    balance,
    loadWeb3Modal,
    web3Modal,
    userSigner,
    isHome
}) => {
  const [breedActive, setBreedActive] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const isConnected = () => address !== undefined

  const [
      mySloties
  ] = useSlotieNFTs(contractNFT, contractBreeding, contractTransfer, balance, address)

  const [
    mySlotieJrs
   ] = useSlotieJrNFTs(contractNFTJr, contractBreeding, address)

  useEffect(() => {
    if (moment().isAfter(BREEDING_START)) {
      setBreedActive(true)
    }
  }, [breedActive])

  useEffect(() => {

  }, [mySloties, mySlotieJrs])

  return (
    <section id="My-Sloties" className="section slotie-section">
      <div className="container">
        <div className="wrapper card my-sloties-card">
          <h2>My Sloties</h2>
          <div className="horizontal-line" />
          <div className="spacer _3em" />
          <div className="grid breed-state-grid" style={{ justifyContent: 'center', display: (isConnected() && !mySloties && !mySlotieJrs) ? 'grid' : 'none' }}>
            <Spinner text={"Your Sloties are loading..."} />
          </div>

          {
            !isConnected() ?
              <div className="grid breed-state-grid" style={{ justifyContent: 'left' }}>
                <ConnectButton
                    loadWeb3Modal={loadWeb3Modal}
                    address={address}
                    userSigner={userSigner}
                    web3Modal={web3Modal}
                    isHome={isHome}       
                />
              </div> : null
          }

          <div className="grid my-sloties-grid">
          {
              isConnected() && mySloties && mySloties.length > 0 ? mySloties.map(item =>
              <CardItem
                key={item.id}
                id={item.id}
                title={item.name}
                image={item.image}
                breedStatus={item.breedStatus}
                isJunior={item.isJunior}
                isSelectable={false}
                remainingDate={new Date(item.breedStart*1000)}
              />) : null
            }
            {
              isConnected() && mySlotieJrs && mySlotieJrs.length > 0 ? mySlotieJrs.map(item =>
              <CardItem
                key={item.id}
                id={item.id}
                title={item.name}
                image={item.image}
                breedStatus={item.breedStatus}
                isJunior={false}
                isSelectable={false}
              />) : null
            }
            {
              isConnected() && mySloties && mySloties.length == 0 && mySlotieJrs
              && mySlotieJrs.length == 0
              ?
                <div className="grid breed-state-grid">
                    <Link to="#" onClick={() => window.location.href = "https://rarible.com/slotie/items"} className="button w-button">You don't have any Sloties. Buy them on opensea.</Link>
                </div> : null
            }
          </div>
          <div className="spacer _3em" />
          {
            breedActive ? (
              <div className="grid breed-state-grid">
                <Link to="/select" className="button w-button">Breed Sloties</Link>
              </div>
            ) : (
              <div className="grid breed-state-grid">
                <a href="#" className="button secondary w-button">Breeding Closed</a>
                <div className="text text-small">Breeding will be available on <span className="text breed-date-text">Feb 11, 2022</span>.
                </div>
              </div>
            )
          }
          {
            selectedItems.length ? (
              <div className="grid breed-state-grid">
                <a href="#" className="button secondary w-button">Breeding Closed</a>
                <div className="text text-small">You need at least two Sloties to breed.</div>
              </div>
            ) : null
          }
        </div>
      </div>
    </section>
  )
}

export default MySlotiesItems
