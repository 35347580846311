import { useState, useEffect } from 'react';

export default function useClock(dynamicUpdate) {
    const [_time, _setTime] = useState(0);

    useEffect(() => {  
        let interval = window.setInterval(() => {            
            _setTime(t => t + 1);
            dynamicUpdate();
        }, 1000);

        return () => clearInterval(interval);
    })

    return []
}