import React from "react"
import Layout from "../components/Layout";
import MySlotiesItems from "../components/MySlotiesItems";

const MySloties = ({
    address,
    userSigner,            
    web3Modal,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    readContracts
}) => {
    return (
        <Layout
            loadWeb3Modal={loadWeb3Modal}
            address={address}
            userSigner={userSigner}
            web3Modal={web3Modal}
        >
    <MySlotiesItems 
            address={address}
            contractNFT={readContracts ? readContracts.Slotie : undefined}
            contractNFTJr={readContracts ? readContracts.SlotieJr : undefined}
            contractBreeding={readContracts ? readContracts.SlotieJrBreeding : undefined}
            contractTransfer={readContracts ? readContracts.WattsTransferExtension : undefined}
            balance={1}
            loadWeb3Modal={loadWeb3Modal}
            userSigner={userSigner}
            web3Modal={web3Modal}
        />
  </Layout>
    )
}

export default MySloties
