import React, { useState,useEffect, useRef } from "react"
import Layout from "../components/Layout";
import { Link } from "react-router-dom"
import CardItem from "../components/CardItem";
import useSlotieNFTs from "../hooks/useSlotieNFT";
import {ethers} from "ethers"
import { useNavigate } from 'react-router';
import Spinner from "../components/Spinner";
import Popup from "../components/Popup";
import ConnectButton from "../components/ConnectButton";
import {useBlocker } from 'react-router-dom'

const twoDecimals = str => {
    //console.log(str)
    const split = str.split(".");
    //console.log(split)
    if (split.length > 1) {
      return split[0] + "." + split[1].substring(0, 2);
    } else {
      return str;
    }
  };

function getArgsFromEvent(contract, receipt, eventName, targetArg) {
    const requestIds = [];
    const logs = receipt.logs;
    const contractInterface = contract.interface

    for (const log_key in logs)
    {
        try {
            const parsed = contractInterface.parseLog(logs[log_key]);
            const name = parsed.name;

            if (name === eventName) {
                requestIds.push(parsed.args[targetArg]);
            }
        } catch (e) {
            continue;
        }

    }

    return requestIds;
}


const Select = ({
    address,
    userSigner,
    web3Modal,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    readContracts,
    writeContracts,
    injectedProvider
}) => {
    let navigate = useNavigate();
  const scrollToAfterSelect = useRef(null)
  const scrollToAfterDeselect = useRef(null)

  const executeScrollAfterSelect = () => scrollToAfterSelect.current.scrollIntoView()
  const executeScrollAfterDeselect = () => scrollToAfterDeselect.current.scrollIntoView()

  const [selectedOne, setSelectedOne] = useState({})
  const [selectedTwo, setSelectedTwo] = useState({})

  const [wattsBalance, setWattsBalance] = useState(undefined)
  const [breedPrice, setBreedPrice] = useState(undefined)    

  const [
    mySloties
    ] = useSlotieNFTs(readContracts.Slotie, readContracts.SlotieJrBreeding, readContracts.WattsTransferExtension, 1, address)

    const isConnected = () => userSigner !== undefined && address !== undefined;
    const isDataLoaded = () => isConnected() && wattsBalance !== undefined && mySloties !== undefined && breedPrice !== undefined;
    const areSlotieSelected = () => selectedOne.id !== undefined && selectedTwo.id !== undefined
    const hasEnoughBalance = () => wattsBalance.gte(breedPrice);
    const canBreed = () => areSlotieSelected() && hasEnoughBalance();

  
  const changeSlotieBreedStatesToActive = () => {
      mySloties.forEach(slotie => {
          if (slotie.breedStatus === 0) {
              slotie.breedStatus = 1
              slotie.oldBreedstatus = 0;
          }
      })
  }

  const changeSlotieBreedStatesToDeActive = () => {
    mySloties.forEach(slotie => {
        if (slotie.oldBreedstatus !== undefined) {
            slotie.breedStatus =  slotie.oldBreedstatus;
        }
    })
  }

  const checkSelectedTwoBreedState = () => {
      if (selectedTwo && selectedTwo.id) {
          if (selectedTwo.oldBreedstatus !== undefined) {
              setSelectedTwo({})
          }
      }
  }

  const onClicked = (id) => {
      if (isDataLoaded() && !isBreeding) {
        if (selectedOne.hasOwnProperty('id') && selectedTwo.hasOwnProperty('id')) {
            return;
          }

          const selectedItem = mySloties.find(item => item.id === id)
          if (selectedItem.id === selectedOne.id) {
            return;
          }

          if (selectedItem.breedStatus !== 1) {
            setError(true)
            return
          }

          if (!selectedOne.hasOwnProperty('id')) {
            setSelectedOne({...selectedItem, isSelectable: false})
            changeSlotieBreedStatesToActive();
            executeScrollAfterSelect()
            return;
          }

          if (!selectedTwo.hasOwnProperty('id')) {
            setSelectedTwo({...selectedItem, isSelectable: false})
            executeScrollAfterSelect()
            return;
          }
      }
  }

  const deselectOne = () => {
      if (!isBreeding) {
        executeScrollAfterDeselect();
        changeSlotieBreedStatesToDeActive();
        checkSelectedTwoBreedState();
        setSelectedOne({})
      }
  }

  const deselectTwo = () => {
      if (!isBreeding) {
        executeScrollAfterDeselect();
        setSelectedTwo({})
      }
  }

  const getBalances = async () => {
    if (address && readContracts && readContracts.WATTS && readContracts.SlotieJrBreeding) {
      console.log("Getting balances");
      readContracts.WATTS.balanceOf(address)
        .then(value => setWattsBalance(value))
        .catch(e => console.log(e));

      readContracts.SlotieJrBreeding.breedPrice()
        .then(value => setBreedPrice(value))
        .catch(e => console.log(e));
    }
  };

  useEffect(() => {
    getBalances();
  }, [address, readContracts]);

  useEffect(() => {
    if (injectedProvider && readContracts && readContracts.WATTS && readContracts.SlotieJrBreeding) {
      injectedProvider.on("block", _ => {
        console.log("block update");
        getBalances();
      });
    }
  }, [injectedProvider, readContracts]);

  const [isBreeding, setIsBreeding] = useState(false);
  const [isError, setIsError] = useState(false);

  const [status, setStatus] = useState(0);
  const [txId, setTxId] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    checkTx();
  }, [status]);

  async function setCorrectErrorMessage(e) {
    setIsError("An unexpected error occured and your transaction did not go through. Please check your wallet or etherscan to see the reason of the failure.");
    const errors = [
        {
            error: "MetaMask Tx Signature: User denied transaction signature",
            message: "Error: You rejected the transaction."
        },
        {
            error: "insufficient funds for gas * price + value",
            message: "Error: You don't have enough Ether in your wallet to complete the transaction."
        }
    ]
    for (let error of errors) {
        if (e && e.message && e.message.includes(error.error)) {
            let _msg = error.message;
            setIsError(_msg)
            return;
        }
    }
}

  const checkTx = () => {
    if (injectedProvider) {
      const txId = localStorage.getItem("PENDING_TX");
      console.log("txId", txId);
      if (txId && txId != "") {
        injectedProvider.getTransactionReceipt(txId).then(receipt => {
          console.log("getTransactionReceipt", receipt);
          if (receipt) {
            localStorage.setItem("PENDING_TX", "");
            setTxId(receipt.transactionHash);
            if (receipt.status == true) {
                setIsBreeding(false);
                let father = getArgsFromEvent(readContracts.SlotieJrBreeding, receipt, "Bred", "father");
                let mother = getArgsFromEvent(readContracts.SlotieJrBreeding, receipt, "Bred", "mother");
                let jr = getArgsFromEvent(readContracts.SlotieJrBreeding, receipt, "Bred", "slotieJr");

                localStorage.setItem("father", father.toString());
                localStorage.setItem("mother", mother.toString());
                localStorage.setItem("jr", jr.toString());
                localStorage.setItem("jr_cooldown", (Math.floor(Date.now() / 1000) + 60).toString())

                localStorage.setItem("fatherData", JSON.stringify(selectedOne))
                localStorage.setItem("motherData", JSON.stringify(selectedTwo))

                navigate('/breed')
            } else {
                setCorrectErrorMessage({});
            }
            getBalances();
            
            setStatus(0);
          } else {
            setTimeout(checkTx, 1000);
          }
        });
      }
    }
  };

  const breed = async () => {
    setIsBreeding(true);
    setIsError(undefined)

    if (!canBreed()) {
        alert("You cannot breed.")
        return;
    }
    console.log(selectedOne)
    console.log(selectedTwo)
    writeContracts.SlotieJrBreeding.breed(selectedOne.id, selectedTwo.id, selectedOne.proof[0], selectedTwo.proof[0], selectedOne.proof[2], selectedTwo.proof[2])
      .then(response => {
        console.log("response", response);
        localStorage.setItem("PENDING_TX", response.hash);
        setStatus(1);
        return response.hash;
      })
      .catch(error => {
        console.log(error);
        setStatus(0);
        setIsBreeding(false);
        setCorrectErrorMessage(error);
      });
  };

  //useBlocker(() => {
  //  return "Your junior is currently being bred. Please stay on the page."
  //}, isBreeding)

  return (
    <Layout
    loadWeb3Modal={loadWeb3Modal}
    address={address}
    userSigner={userSigner}
    web3Modal={web3Modal}
    >
      {
        error ? <Popup handleClick={() => setError(false)} text="Please select Sloties which can be breed" /> : null
      }
      <main className="section main-section" ref={scrollToAfterSelect}>
        <div className="container">
          <div className="wrapper card my-sloties-card">
            <h2 className="heading heading-large centred">Select your Sloties for breeding.</h2>
            <div className="horizontal-line" />
            <div className="spacer _3em" />
            <div className="grid select-sloties-grid">
              {
                selectedOne.hasOwnProperty('id') ? (
                  <div id="w-node-f24d509c-6f27-8c45-1692-4a522acfc1ab-eeae4888" className="wrapper select-wrapper" style={{border: 0}} title="Click for unselect">
                    <CardItem
                        key={selectedOne.id}
                        id={selectedOne.id}
                        title={selectedOne.name}
                        image={selectedOne.image}
                        breedStatus={selectedOne.breedStatus}
                        isJunior={selectedOne.isJunior}
                        isSelectable={selectedOne.breedStatus === 1}
                        close={deselectOne}
                        handleClick={deselectOne}
                    />
                  </div>
                ) : (<div onClick={executeScrollAfterDeselect} id="w-node-f24d509c-6f27-8c45-1692-4a522acfc1ab-eeae4888" className="wrapper select-wrapper">
                  <span style={{ textAlign: 'center', display: 'block', position: 'relative', top: '50%' }}>Please Select your Alpha Slotie</span>
                </div>)
              }
              {
                selectedTwo.hasOwnProperty('id') ? (
                  <div id="w-node-_0e636f3c-2395-2922-c82e-1c5bba8788da-eeae4888" className="wrapper select-wrapper" style={{border: 0}} title="Click for unselect">
                    <CardItem
                        key={selectedTwo.id}
                        id={selectedTwo.id}
                        title={selectedTwo.name}
                        image={selectedTwo.image}
                        breedStatus={selectedTwo.breedStatus}
                        isJunior={selectedTwo.isJunior}
                        isSelectable={selectedTwo.breedStatus === 1}
                        close={deselectTwo}
                        handleClick={deselectTwo}
                    />
                  </div>
                ) : (<div onClick={executeScrollAfterDeselect} id="w-node-_0e636f3c-2395-2922-c82e-1c5bba8788da-eeae4888" className="wrapper select-wrapper">
                  <span style={{ textAlign: 'center', display: 'block', position: 'relative', top: '50%' }}>Please Select your Omega Slotie</span>
                </div>)
              }
            </div>
            <div className="spacer _2em" />
            <div className="grid slotie-select-button-grid" ref={scrollToAfterDeselect}>
              {isDataLoaded() &&
                <div className="grid slotie-select-button-grid">
                <div className="grid balance-grid">
                    <div className="wrapper balance-wrapper">
                    <div>Watts balance:</div>
                    <div className="spacer _0-5em" />
                    <div className="tag">{twoDecimals(ethers.utils.formatEther(wattsBalance.toString()))} Watts</div>
                    </div>
                    <div className="wrapper balance-wrapper">
                    <div className="heading">Breeding fee:</div>
                    <div className="spacer _0-5em" />
                    <div className="tag">{twoDecimals(ethers.utils.formatEther(breedPrice.toString()))} Watts</div>
                    </div>
                </div>
                {
                   canBreed() && !isBreeding &&
                    <Link to="#" onClick={breed} className="button w-button">Breed now</Link>
                }
                {
                    isError &&
                    <div className="grid breed-state-grid">
                        <div className="text text-small">{isError}</div>
                    </div>
                }
                {
                    !hasEnoughBalance() &&
                    <a href="#" className="button secondary w-button">Insufficient balance</a>
                }
                </div>
            }
            </div>
            <div className="spacer _3em" />

            <div className="grid breed-state-grid" style={{ justifyContent: 'center', display: (isConnected() && !mySloties) ? 'grid' : 'none' }}>
              <Spinner text={"Your Sloties are loading..."} />
            </div>

            <div className="grid breed-state-grid" style={{ justifyContent: 'center', display: isBreeding ? 'grid' : 'none' }}>
              <Spinner text={"Your transaction is loading..."} />
            </div>

            {
              !isConnected() ?
                <div className="grid breed-state-grid" style={{ justifyContent: 'center' }}>
                  <ConnectButton
                    loadWeb3Modal={loadWeb3Modal}
                    address={address}
                    userSigner={userSigner}
                    web3Modal={web3Modal}
                />
                </div> : null
            }

            <div className="grid my-sloties-grid">
            {
              isConnected() && mySloties && mySloties.length && mySloties.length > 0 ? mySloties.map(item =>
              <CardItem
                key={item.id}
                id={item.id}
                title={item.name}
                image={item.image}
                breedStatus={item.breedStatus}
                isJunior={item.isJunior}
                isSelectable={item.breedStatus === 1}
                isDisabled={selectedOne.id === item.id || selectedTwo.id === item.id}
                handleClick={onClicked}
                remainingDate={new Date(item.breedStart * 1000)}
              />) : null
            }
            {
              isConnected() && mySloties && mySloties.length && mySloties.length == 0 ?
                <div className="grid breed-state-grid">
                    <Link to="https://rarible.com/slotie/items" className="button w-button">You don't have any Sloties. Buy them on opensea.</Link>
                </div>
                : null
            }
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Select
