import React from 'react'

const Spinner = ({ text, type = 'default', width = 5, disableSpinner = false }) => {
  let color = 'white';
  if (type === 'success') {
    color = '#58d658';
  } else if (type === 'error') {
    color = '#d50000';
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <span style={{ marginRight: 10, color }}>{text}</span>
        <div data-w-id="35b0155a-9391-907b-f6f8-8adef6aeb330" style={{ width: `${width}em` }} data-animation-type="lottie" data-src="https://uploads-ssl.webflow.com/61a2301c1aba757f9b2521aa/61a2301c1aba75799b2521d9_lottieflow-loading-04-1-ffffff-easey.json" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="3.1458333333333335" data-duration={0} className="lottie loading-lottie" />
      </div>
    </div>
  )
}

export default Spinner
