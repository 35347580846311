import React  from 'react'
import moment from 'moment'

// breedStatus:
// 0 = can not breed
// 1 = can breed
// 2 = loading
// 3 = parent
// 4 = remaining days

const BreedBadge = ({status, days}) => {
  if (status === 1) {
    return <div className="tag">Can Breed</div>;
  }

  if (status === 2) {
    return <div className="tag loading-tag">Loading Breeding</div>
  }

  if (status === 3) {
    return <div className="tag parent-tag">Parent</div>
  }

  if (status === 4) {
    return <div className="tag error-tag">Cooldown Remaining {String(days).replace('-', '- ')} days</div>
  }

  return <div className="tag error-tag">Can't Breed</div>
}

const CardItem = ({id, title, image, breedStatus, isJunior, isSelectable, handleClick, close, isDisabled, remainingDate}) => {    
  if (remainingDate) {
    const a = moment();
    const b = moment(remainingDate);
    remainingDate = a.diff(b, 'days')
  }

  return (
    <div onClick={e => handleClick ? handleClick(id) : null} style={{ opacity: isDisabled ? '.2' : '1' }} className={`grid single-slotie-grid${isSelectable && !isDisabled ? ' selectable-slotie' : ''}`} id={isJunior ? 'w-node-_1336232c-9c34-fc72-c6f2-f34ba2ced908-61896816' : ''}>
      <img src={image}
           loading="lazy"
           sizes="(max-width: 767px) 82vw, (max-width: 991px) 40vw, 25vw"
           alt="" className="image slotie-image"
      />
      {
        close ? <span onClick={close} className="remove-selected">&times;</span> : null
      }
      <div className="wrapper slotie-card-detail-wrapper">
        <div className="grid slotie-title-grid">
          <div className="heading heading-small no-margin">{title}</div>
          <a href="https://rarible.com/slotie/items" target="_blank" className="link-block social-link w-inline-block">
            <img src="images/Opensea-Logo.svg" loading="eager" alt="Opensea Logo." className="icon social-icon"/>
          </a>
        </div>
        <div className="spacer _0-5em"/>
        <div className="horizontal-line"/>
        <div className="spacer _0-75em"/>
        <div className="grid slotie-meta-grid">
          <BreedBadge status={breedStatus} days={remainingDate} />
          {/*<div className="divider">•</div>*/}
          {/*<div className="wrapper timer-wrapper">*/}
          {/*  <img src="images/Clock-Icon.svg" loading="lazy" alt="" className="icon timer-icon"/>*/}
          {/*  <div className="spacer _0-5em"/>*/}
          {/*  <div className="text text-xs">Fast <span className="text cooldown-time-text">(1d)</span></div>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  )
}

export default CardItem;
