import React, { useState, useEffect, useCallback } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'
import Select from './pages/Select'
import Breed from './pages/Breed'
import Junior from "./pages/Junior";
import MySloties from "./pages/MySloties";
import Status from "./pages/Status"

import { NETWORKS } from "./constants";
import {
    useContractLoader,
    useContractReader,
    useUserProviderAndSigner,
  } from "eth-hooks";
import deployedContracts from "./contracts/hardhat_contracts.json";
import { ethers } from "ethers";
import { Web3ModalSetup } from "./helpers";

const initialNetwork = NETWORKS.ropsten;

const web3Modal = Web3ModalSetup();

function App() {

const networkOptions = [initialNetwork.name];

  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0]);

  const targetNetwork = NETWORKS[selectedNetwork];

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, null, false);
  const userSigner = userProviderAndSigner.signer;

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  // const contractConfig = useContractConfig();

  const contractConfig = { deployedContracts: deployedContracts || {} };

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(userSigner, contractConfig);

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(userSigner, contractConfig, selectedChainId);

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
    // eslint-disable-next-line
  }, [setInjectedProvider]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/breed" element={<Breed />} />
          <Route path="/select" element={<Select 
            address={address}
            userSigner={userSigner}               
            web3Modal={web3Modal}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            readContracts={readContracts}
            writeContracts={writeContracts}
            injectedProvider={injectedProvider}          
          />} />
          <Route path="/junior" element={<Junior  address={address}
            userSigner={userSigner}               
            web3Modal={web3Modal}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            readContracts={readContracts}
            writeContracts={writeContracts}
            injectedProvider={injectedProvider}   />} />
            <Route path="/my-sloties" element={<MySloties 
                address={address}
                 userSigner={userSigner}               
                 web3Modal={web3Modal}
                 loadWeb3Modal={loadWeb3Modal}
                 logoutOfWeb3Modal={logoutOfWeb3Modal}
                 readContracts={readContracts}
                 writeContracts={writeContracts}
                 injectedProvider={injectedProvider}
            />} />
            <Route exact path="/status" element={
                <Status 
                    userSigner={userSigner}               
                    web3Modal={web3Modal}
                    loadWeb3Modal={loadWeb3Modal}
                    logoutOfWeb3Modal={logoutOfWeb3Modal}
                    readContracts={readContracts}
                    writeContracts={writeContracts}
                    injectedProvider={injectedProvider} 
                    address={address}
                />
            } />
          <Route exact path="/" element={
            <Home 
            address={address}
            userSigner={userSigner}               
            web3Modal={web3Modal}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            readContracts={readContracts}
            writeContracts={writeContracts}
            injectedProvider={injectedProvider}  
            />
           } />            
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
