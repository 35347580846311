import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { MoralisProvider } from "react-moralis";
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <MoralisProvider appId="f8JKUl3u6quU9cfAbfbUuzTjcIUv34B9fHMLqzIP" serverUrl="https://6ny0pcnrl6sq.usemoralis.com:2053/server">
        <App />
    </MoralisProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
