import React from 'react'

const wrapper = {
    width: '100%',
    height: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    display: 'flex',
    background: '#000000b0',
    zIndex: '999',
}

const inner = {
  display: 'block',
  margin: 'auto',
  marginTop: '15%',
  padding: '20px 40px',
  background: '#211f23',
  boxShadow: '1px 1px 1px #211f23',
  border: '2px solid #fff',
  borderRadius: '4px',
  position: 'relative',
}

const times = {
  top: '-4px',
  right: '6px',
}

const Popup = ({ text, handleClick }) => {
  const [active, setActive] = React.useState(true);

  return (
    <div style={{ ...wrapper, 'display': active ? 'flex' : 'none' }}>
      <div style={inner}>
        {text}
        <span onClick={handleClick} className="remove-selected" style={times}>&times;</span>
      </div>
    </div>
  )
}

export default Popup
