import React, {useEffect} from 'react'

const ConnectButton = ({ text, loadWeb3Modal, address, userSigner, web3Modal, isHome }) => {

  const connected = () => userSigner !== undefined && address !== undefined

  useEffect(() => {
    if (web3Modal && loadWeb3Modal && !isHome && web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  return (
      <>
        {
            connected() ?
                <></>
            :
            <div style={{ textAlign: 'left' }}>
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
                    <button className="button w-button" onClick={loadWeb3Modal}>Connect Wallet</button>
                    {/*<span style={{ marginRight: 10, color: "white" }} onClick={loadWeb3Modal}>Connect Wallet</span>        */}
                </div>
            </div>
        }
      </>
  )
}

export default ConnectButton
