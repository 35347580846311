import { useState, useEffect } from 'react';
import proofs from "../proofs/proofs.json";
import axios from "axios";
import { useMoralisWeb3Api } from "react-moralis";
const throttledQueue = require('throttled-queue');
const throttle = throttledQueue(10, 1000, true);

export default function useSlotieNFTs(contractNFT, contractBreeding, contractTransfer, balance, address) {
    /**
     * mySloties format
     * {
     *  id,
     *  name,
     *  image,
     *  breedStatus, (1 = can breed) (2 = loading) (3 = parent) (4 = cooldown) (0 = can't breed)
     *  breedStart,
     *  proof,
     *  isJunior
     * }
     */
    const [mySloties, setMySloties] = useState(undefined);   
    const Web3Api = useMoralisWeb3Api()

    useEffect(() => {
        if (contractNFT && contractBreeding && address && contractTransfer && balance > 0) {            
            (async function(){
                try {
                    //let usersSloties = await Web3Api.account.getNFTsForContract({"chain":"ropsten","address":address,"token_address":contractNFT.address} );
                    //usersSloties = usersSloties.result
                    let usersSloties = await contractTransfer.slotieWalletOfOwner(address);
                    usersSloties = usersSloties.map(s => ({ token_id: s.toNumber()}))
                    
                    if (usersSloties && usersSloties.length > 0) {
                        console.log("Getting data from each slotie")
                        const maxBreedable = (await contractBreeding.maxBreedableJuniors()).toNumber()
                        const bred = (await contractBreeding.bornJuniors()).toNumber();


                        let promises = usersSloties.map(async (_slotie) => throttle(async () => {
                            let _token = parseInt(_slotie.token_id);
                            let _tokenUri = await contractNFT.tokenURI(_token);                           
                            let metadata = await axios.get(_tokenUri);
                            metadata = metadata.data
                            let image =  metadata.image ? metadata.image.replace("gateway.pinata.cloud", "sloties.mypinata.cloud") :  metadata.image
                            let lastBred = (await contractBreeding.slotieToLastBreedTimeStamp(_token)).toNumber();
                            let _proof = proofs && proofs.proofs ? proofs.proofs[_token.toString()] || [] : [];

                            let breedStatus;
                            let breedStart;                       
                            let now = Math.floor(Date.now() / 1000)

                            if (lastBred === 0) {
                                breedStart = _proof[0];
                                breedStatus = now >= breedStart ? 1 : 0
                            } else {
                                breedStart = lastBred + 86400 * 120;
                                breedStatus = now >= breedStart ? 1 : 4
                            }

                            return {
                                id: _token,
                                name: metadata.name,
                                image: image,
                                breedStatus: bred >= maxBreedable ? 0 : breedStatus,
                                breedStart,
                                proof: _proof,
                                isJunior: false
                            }
                        })) 
                        
                        let result = await Promise.all(promises);
                        setMySloties(result);
                    } else {
                        console.log("no sloties found")
                        setMySloties([])
                    } 
                } catch(e) {
                    console.log(e)
                }                                           
            })()
        }
    }, [contractNFT, contractBreeding, balance, address, contractTransfer])

    return [
        mySloties
    ]
}