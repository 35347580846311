import React from 'react'
import './loader.css'

const CogLoader = () => (
  <div className="loading">
    <div className="loading-animation">
      <span className="img-loading loading-big" />
      <span className="img-loading loading-big2" />
      <span className="img-loading loading-small" />
    </div>
  </div>
)

export default CogLoader
