import { useState, useEffect } from 'react';
import proofs from "../proofs/proofs.json";
import axios from "axios";
import { useMoralisWeb3Api } from "react-moralis";
const throttledQueue = require('throttled-queue');
const throttle = throttledQueue(10, 1000, true);

export default function useSlotieJrNFTs(contractNFT, contractBreeding, address) {
    /**
     * mySloties format
     * {
     *  id,
     *  name,
     *  image,
     *  breedStatus, (1 = can breed) (2 = loading) (3 = parent) (0 = can't breed)
     *  breedStart,
     *  proof,
     *  isJunior
     * }
     */
    const [mySlotieJrs, setMySlotieJrs] = useState(undefined);   
    const Web3Api = useMoralisWeb3Api()

    useEffect(() => {

        if (contractNFT && contractBreeding && address) {
            (async function(){
                let balance = (await contractNFT.balanceOf(address)).toNumber();

                if (balance > 0) {
                    let promises = [];
                    for (let i = 0; i < balance; i++) {
                        promises.push(throttle(async () => {
                            return await contractNFT.tokenOfOwnerByIndex(address, i);
                        }))
                    }
                    let tokens = await Promise.all(promises);

                    promises = tokens.map(async (token) => throttle(async () => {
                        let _token = token.toNumber()
                        let _tokenUri = await contractNFT.tokenURI(_token);                           
                        let metadata = await axios.get(_tokenUri);
                        metadata = metadata.data
                        let image =  metadata.image //? metadata.image.replace("gateway.pinata.cloud", "sloties.mypinata.cloud") :  metadata.image

                        return {
                            id: _token,
                            name: metadata.name,
                            image: image,
                            breedStatus: null,
                            breedStart: null,
                            proof: null,
                            isJunior: false
                        }
                    })) 
                    
                    let result = await Promise.all(promises);
                    console.log(`Compiled the following Slotie Jr data`)
                    console.log(result)
                    setMySlotieJrs(result);                                        
                }  else {
                    console.log("no slotie juniors found")
                    setMySlotieJrs([])
                }                                            
            })()
        }
    }, [contractNFT, contractBreeding, address])

    return [
        mySlotieJrs
    ]
}