import React, { useState, useEffect } from 'react'
import Layout from "../components/Layout";
import moment from 'moment'
import MySlotiesItems from '../components/MySlotiesItems'


const BREEDING_START = '2022-02-11 00:00:01'

const Home = ({
    address,
    userSigner,            
    web3Modal,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    readContracts
}) => {
  const [breedActive, setBreedActive] = useState(false)

  useEffect(() => {
    if (moment().isAfter(BREEDING_START)) {
      setBreedActive(true)
    }
  }, [breedActive])

  

  return (
    <Layout 
        isHome={true} 
        loadWeb3Modal={loadWeb3Modal}
        address={address}
        userSigner={userSigner}
        web3Modal={web3Modal}
    >
      <div>
        <main id="Home" className="section hero-section">
          <div className="container">
            <div className="grid hero-grid">
              <div className="wrapper">
                <div className="wrapper">
                  <h1 className="heading heading-xl">Introducing breeding.</h1>
                  <p className="text">In the NFT world, machines can breed. Slotie owners can breed their Sloties to
                    create
                    another series of NFTs called <strong>Junior Sloties</strong>. You'll need a total of 1800 Watts and at least 2
                    Sloties to breed.</p>
                  <div className="spacer _0-5em"/>
                  <div className="wrapper button-row hero-button-row">
                    <a href="#My-Sloties" className="button w-button">My Sloties</a>
                    <a href="/status" className="button w-button">Check Status</a>
                    {
                      !breedActive ? (
                        <>
                          <a href="#" className="button secondary w-button">Breeding Closed</a>
                          <div className="text text-small">Breeding will be available on <span className="text breed-date-text">Feb 11, 2022</span>.</div>
                        </>
                      ) : null
                    }
                  </div>
                </div>
              </div>
              <div data-w-id="8976eeec-801c-a0ff-1530-9df20364d267" data-animation-type="lottie"
                   data-src="documents/Breed-your-Sloties.json" data-loop={1} data-direction={1} data-autoplay={1}
                   data-is-ix2-target={0} data-renderer="svg" data-default-duration="7.4" data-duration={0}
                   className="lottie hero-watts-lottie"/>
            </div>
            <div className="wrapper hero-scroll-lottie-wrapper">
              <a href="#My-Sloties" className="link-block hero-scroll-lottie-link w-inline-block">
                <div data-w-id="017efade-af9e-6411-1bb8-95d86e52bd23" data-animation-type="lottie"
                     data-src="documents/lottieflow-scroll-down-09-ffffff-easey.json" data-loop={1} data-direction={1}
                     data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration={2}
                     data-duration={0} className="lottie hero-scroll-lottie"/>
              </a>
            </div>
          </div>
        </main>
        <MySlotiesItems 
            address={address}
            contractNFT={readContracts ? readContracts.Slotie : undefined}
            contractNFTJr={readContracts ? readContracts.SlotieJr : undefined}
            contractBreeding={readContracts ? readContracts.SlotieJrBreeding : undefined}
            contractTransfer={readContracts ? readContracts.WattsTransferExtension : undefined}
            balance={1}
            loadWeb3Modal={loadWeb3Modal}
            userSigner={userSigner}
            web3Modal={web3Modal}
            isHome={true}
        />
      </div>
    </Layout>
  )
}

export default Home;
